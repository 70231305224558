import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import config, { device } from '../styles/config'
import CommissionProjectCard, { Text as ComissionsText } from '../components/CommissionProjectCard'

const Container = styled.div`
  margin: 0 auto;
  margin-top: 8.33rem;
  margin-bottom: 5rem;
  width: 100%;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  position: relative;

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const YearContainer = styled.div`
  position: relative;
`

const YearLabel = styled(ComissionsText)`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  padding-right: 1rem;

  @media ${ device.sm } {
    position: static;
    margin-bottom: 1rem;
    padding-right: initial;
    transform: none;
  }
`

const CommissionsPage = ({ data: { allDatoCmsCommission: { group } } }) => {
  return (
    <Container>
      {group.slice().reverse().map(({ fieldValue: year, nodes: projects }, index) => (
        <YearContainer key={index}>
          <YearLabel>{year}</YearLabel>
          {projects.map((project, index) => <CommissionProjectCard key={index} {...project}/>)}
        </YearContainer>
      ))}
    </Container>
  )
}

export const query = graphql`
  query CommissionsQuery {
    allDatoCmsCommission(sort: {fields: position}) {
      group(field: year) {
        fieldValue
        nodes {
          name
          client
          year
          projectType
          thumbnailWidth
          thumbnail {
            fluid(maxWidth: 800, imgixParams: {
              fm: "jpg"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CommissionsPage
