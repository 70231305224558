import PropTypes from 'prop-types'
import React, { createRef, Component } from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import GatsbyImage from 'gatsby-image'

import { Col, Row } from 'react-styled-flexboxgrid'
import slugify from '../utils/slugify'
import ShuffleText from '../utils/shuffleText'
import isMobile from '../utils/isMobile'
import config, { device } from '../styles/config'

const appear = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`

const Text = styled.div`
  font-family: 'GT America', sans-serif;
  font-size: 0.77rem;
  line-height: 1.33;
  color: ${ config.colors.darkThemeText };
  animation: ${ appear } 1s 0.1s;
  animation-fill-mode: forwards; 
  opacity: 0;
  visibility: hidden;


  @media ${ device.sm } {
    font-size: 1.15rem;
  }
`

const Thumbnail = styled(GatsbyImage)`
  opacity: 0;
  visibility: hidden;
  width: ${ props => `${ props.width }%` };
  /* enables fade: */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  @media ${ device.sm } {
    display: block;
    opacity: 1;
    visibility: visible;
  }
`

const Project = styled.div`
  display: block;
  padding-bottom: 1rem;
  
  &:hover {
    ${ Thumbnail } {
      opacity: 1;
      visibility: visible;
    }
  }

  @media ${ device.sm } {
    margin-bottom: 6.9rem;
    padding-bottom: 0;
  }
`

const ThumbnailContainer = styled.div`
  position: fixed;
  z-index: -1;
  margin: 0 auto;
  width: 100%;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${ device.sm } {
    position: initial;
    width: initial;
    height: initial;
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1rem;
  }
`

const ThumbnailContainerInner = styled(Col)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0 !important;

  @media ${ device.sm } {
    padding-right: initial;
    padding-left: initial;
    display: block !important;
  }
`

class CommissionProjectCard extends Component {
  constructor (props) {
    super(props)

    this.animationTarget = createRef(null)
    this.shouldAnimate = !isMobile()
    this.shuffle = null
    this.timeout = null
  }

  componentDidMount () {
    if (this.shouldAnimate) {
      this.shuffle = new ShuffleText(this.animationTarget.current, {})
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  startShuffle = () => {
    if (this.shouldAnimate && this.shuffle) {
      clearTimeout(this.timeout)
      this.shuffle.start()
    }
  }

  endShuffle = () => {
    if (this.shouldAnimate && this.shuffle) {
      clearTimeout(this.timeout)
    }
  }

  render () {
    const {
      thumbnail,
      thumbnailWidth,
      client,
      name,
      projectType,
    } = this.props

    return (
      <Project
        onMouseEnter={this.startShuffle}
        onMouseLeave={this.endShuffle}
      >
        <ThumbnailContainer>
          <ThumbnailContainerInner xs={8} sm={4} smOffset={4}>
            <Thumbnail
              fluid={thumbnail.fluid}
              width={thumbnailWidth}
            />
          </ThumbnailContainerInner>
        </ThumbnailContainer>
        <Text>
          {client}
        </Text>
        <Text ref={this.animationTarget}>
          {name}
        </Text>
        <Text>
          {projectType}
        </Text>
      </Project>
    )
  }
}

CommissionProjectCard.propTypes = {

}

export { Text }
export default CommissionProjectCard
